<template>
  <div class="main-content-wrap import-list">
    <div class="success_img">
      <img src="@/assets/rap/successful_image_no.png" alt="">
    </div>

    <div class="Payment_size">
      Payment successful!
    </div>

    <div class="Payment_tip">
      The goods will be delivered to you in the next few days.
    </div>

    <div class="Payment_btn">
      <div class="Payment_btn_one">
        <el-button class="Browse_products_btn">Browse products</el-button>
      </div>

      <div>
        <el-button class="Check_order">Check order</el-button>
      </div>
    </div>

  </div>
</template>
<script>
  export default {

  }

</script>
<style lang="scss" scoped>
  .import-list {
    padding-top: 25px;
  }

  .main-content-wrap {
    width: 1300px;
    margin: 0 auto;
    box-sizing: border-box;

    .success_img {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 86px;
    }

    .Payment_size {
      margin-top: 20px;
      height: 22px;
      font-size: 17px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      text-align: center;
      color: #999999;
    }

    .Payment_tip {
      margin-top: 10px;
      font-size: 18px;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: 500;
      text-align: center;
      color: #1a1a1a;
    }

    .Payment_btn {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 30px;

      .Payment_btn_one {
        margin-right: 15px;

        .Browse_products_btn {
          width: 185px;
          border: 1px solid #ff6c23;
          color: #ff6c23;
          font-size: 17px;
          font-weight: 600;
          font-family: PingFangSC, PingFangSC-Semibold;
        }


      }

      .Check_order {
        width: 185px;
        background: #ff6c23;
        border: 1px solid #ff6c23;
        color: white;
        font-size: 17px;
        font-weight: 600;
        font-family: PingFangSC, PingFangSC-Semibold;

      }
    }

  }

</style>
